@import '../common/vars';
@import '../common/mixins';
@mixin hoverGradientOverlap($backgroundURL) {
    background: linear-gradient(to right,rgba(39, 47, 115, 1) 0%, rgba(48, 62, 165, 0.87) 70%, rgba(73, 84, 252, 0.6) 100%),url($backgroundURL) no-repeat;
    background-position: right bottom;
    transition: all .3s ease-in-out;
}
.decision-hub {
    background: url(/pi/global/decisionhub/bitmap@3x.jpg) top;
    background-size: 100% 1660px;
    background-repeat: repeat-y;
    margin: 0;
    font-family: $font-pn;
    @include breakpoint(1) {
        background: url(/pi/global/decisionhub/mobile-bg-image@3x.png);
        background-size: auto;
        background-position: 0 0;
    }

    h1 {
        font-size: 60px;
        font-size: 60px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0.42px;
        text-align: center;
        color: #ffffff;
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
        font-family: $font-pn;
        padding-bottom: 15px;
        @include breakpoint(2) {
            max-width: 700px;
        }
        @include breakpoint(1) {
            font-size: 28px;
            line-height: 1.07;
            letter-spacing: 0.29px;
            max-width: 320px;
        }
    }

    &-title-text {
        font-size: 1.125rem;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.37rem;
        letter-spacing: normal;
        text-align: center;
        color: #C6D8FF;
        font-family: $font-pn-light;
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0;
        text-transform: capitalize;
        b {
            font-family: $font-pn-semibold;
            text-transform: uppercase;
        }
        @include breakpoint(2) {
            max-width: 700px;
        }
        @include breakpoint(1) {
            font-size: 14px;
            line-height: 1.25;
            max-width: 320px;
        }
    }

    .align-centre {
        display: flex;
        justify-content: center;
    }

    &-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        max-width: 1268px;
        margin: auto;
        @include breakpoint(2) {
            max-width: 500px;
        }
    }

    &-cards {
        background: #ffffff;
        overflow: hidden;
        position: relative;
        height: 110px;
        margin: 10px;
        background-size: 100% 100%;
        border-radius: 4px;
        &:hover {
            .child:not(.disabled) {
                .decision-hub-cards-title {
                    color: white;
                    transition: all .3s ease-in-out;
                    &:after {
                        content: url(/pi/global/decisionhub/cards/caret_White_svg.svg);
                        width: 6.5px;
                        height: 13px;
                        margin-left: 10px;
                    }
                }

                .decision-hub-cards-text {
                    color: white;
                    transition: all .3s ease-in-out;
                }
                .decision-hub-cards-coming-text {
                    color: #93deff;
                    transition: all .5s;
                }

                .decision-hub-cards-button {
                    background-color: #ffffff;
                    box-shadow: 0 0 10px 6px rgba(0, 0, 0, 0.2);
                    color: #303ea5;
                    transition: all .5s;
                }
            }


        }
        
        @include breakpoint(4) {
            flex-basis: 400px;
        }

        @include breakpoint(3) {
            flex-basis: 400px;
        }

        @include breakpoint(2) {
            flex-basis: 400px;
        }

        @include breakpoint(1) {
            flex-basis: 351px;
        }

        .child {
            padding: 2.625rem  2.625rem  2.625rem 1.875rem;
            background-color: #ffffff;
            height: 100%;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            text-decoration: none;
            border-radius: 4px;
            // &:before {
            //     content: "";
            //     display: none;
            //     height: 100%;
            //     width: 100%;
            //     z-index: 0;
            //     position: absolute;
            //     top: 0;
            //     left: 0;
            //     background-image: linear-gradient(299deg, rgba(73, 84, 252, 0.6) 100%, rgba(48, 62, 165, 0.87) 57%, #272f73 3%);
            // }
        }

        .disabled {
            box-sizing: border-box;
            height: 100%;
            &:before {
                content: "Coming soon...";
                display: block;
                color: #333132;
                padding-left: 30px;
                padding-top: 60px;
                height: 100%;
                width: 100%;
                z-index: 0;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0.55;
                background: #ffffff;
            }

            .decision-hub-cards-text {
                display: none;
            }

            .decision-hub-cards-coming-text {
                display: none;
            }

            .decision-hub-cards-button {
                display: none;
            }
        }



        .oncology {
            background: url(/pi/global/decisionhub/cards/oncCard@3x.png) no-repeat;
            // background-size: calc(15% + 30px) calc(100% + 0px);
            background-position: right bottom;
            &:hover {
                @include hoverGradientOverlap("/pi/global/decisionhub/cards/oncCard@3x.png");
            }
        }

        .neurology {
            background: url(/pi/global/decisionhub/cards/neuroCard@3x.png) no-repeat;
            // background-size: calc(15% + 30px) calc(100% + 0px);
            background-position: right bottom;
            &:hover {
                @include hoverGradientOverlap("/pi/global/decisionhub/cards/neuroCard@3x.png");
            }
        }

        .cardiology {
            background: url(/pi/global/decisionhub/cards/cardiologyCard@3x.png) no-repeat;
            // background-size: calc(15% + 30px) calc(100% + 0px);
            background-position: right bottom;
            &:hover {
                @include hoverGradientOverlap("/pi/global/decisionhub/cards/cardiologyCard@3x.png");
            }
        }

        .diabetes {
            background: url(/pi/global/decisionhub/cards/diabetesCard@3x.png) no-repeat;
            // background-size: calc(15% + 30px) calc(100% + 0px);
            background-position: right bottom;
            &:hover {
                @include hoverGradientOverlap("/pi/global/decisionhub/cards/diabetesCard@3x.png");
            }
        }

        .hematology {
            background: url(/pi/global/decisionhub/cards/hiv-400-x-225@3x.png) no-repeat;
            // background-size: calc(15% + 30px) calc(100% + 0px);
            background-position: right bottom;
            &:hover {
                @include hoverGradientOverlap("/pi/global/decisionhub/cards/hiv-400-x-225@3x.png");
            }
        }

        .dermatology {
            background: url(/pi/global/decisionhub/cards/dermatologyCard@3x.png) no-repeat;
            // background-size: calc(15% + 30px) calc(100% + 0px);
            background-position: right bottom;
            &:hover {
                @include hoverGradientOverlap("/pi/global/decisionhub/cards/dermatologyCard@3x.png");
            }
        }

        .hiv {
            background: url(/pi/global/decisionhub/cards/hivCard@3x.png) no-repeat;
            // background-size: calc(15% + 30px) calc(100% + 0px);
            background-position: right bottom;
            &:hover {
                @include hoverGradientOverlap("/pi/global/decisionhub/cards/hivCard@3x.png");
            }
        }

        .pulmonarymedicine {
            background: url(/pi/global/decisionhub/cards/pulmonaryCard@3x.png) no-repeat;
            // background-size: calc(15% + 30px) calc(100% + 0px);
            background-position: right bottom;
            &:hover {
                @include hoverGradientOverlap("/pi/global/decisionhub/cards/pulmonaryCard@3x.png");
            }
        }
        
        .psychiatry {
            background: url(/pi/global/decisionhub/cards/psychiatryCard@3x.png) no-repeat;
            // background-size: calc(15% + 30px) calc(100% + 0px);
            background-position: right bottom;
            &:hover {
                @include hoverGradientOverlap("/pi/global/decisionhub/cards/psychiatryCard@3x.png");
            }
        }
        .rheumatology {
            background: url(/pi/global/decisionhub/cards/rheumatologyCard@3x.png) no-repeat;
            // background-size: calc(15% + 30px) calc(100% + 0px);
            background-position: right bottom;
            &:hover {
                @include hoverGradientOverlap("/pi/global/decisionhub/cards/rheumatologyCard@3x.png");
            }
        }
        .criticalcare {
            background: url(/pi/global/decisionhub/cards/criticalcareCard@3x.png) no-repeat;
            // background-size: calc(15% + 30px) calc(100% + 0px);
            background-position: right bottom;
            &:hover {
                @include hoverGradientOverlap("/pi/global/decisionhub/cards/criticalcareCard@3x.png");
            }
        }
        .nephrology {
            background: url(/pi/global/decisionhub/cards/nephrologyCard.png) no-repeat;
            // background-size: calc(15% + 30px) calc(100% + 0px);
            background-position: right bottom;
            &:hover {
                @include hoverGradientOverlap("/pi/global/decisionhub/cards/nephrologyCard.png");
            }
        }
        .infectiousdiseases {
            background: url(/pi/global/decisionhub/cards/InfectiousDiseaseCard.png) no-repeat;
            // background-size: calc(15% + 30px) calc(100% + 0px);
            background-position: right bottom;
            &:hover {
                @include hoverGradientOverlap("/pi/global/decisionhub/cards/InfectiousDiseaseCard.png");
            }
            .decision-hub-cards-title{
                width:250px;
            }
        }

        &-title {
            color: #000000;
            font-size: 26px;
            font-family: $font-pn-semibold;
            text-transform: uppercase;
            margin-bottom: 15px;
            line-height: 1.625rem;
            &:after {
                content: url(/pi/global/decisionhub/cards/caret_Purple_svg.svg);
                width: 6.5px;
                height: 13px;
            }
            
        }

        &-text {
            color: #333132;
            font-size: 16px;
            font-family: $font-pn-light;
            max-width: 280px;
        }

        &-coming-text {
            color: #174580;
            font-size: 14px;
            font-family: $font-pn-ital;
        }

        &-button {
            height: 35px;
            border-radius: 17.5px;
            background-color: #303ea5;
            color: #ffffff;
            text-transform: uppercase;
            line-height: 35px;
            display: inline-block;
            width: 175px;
            padding: 0 20px;
            font-size: 13px;
            font-family: $font-pn-semibold;
            position: absolute;
            bottom: 30px;
        }
    }

    &-answers {
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 100px;
        padding-bottom: 70px;
        @include breakpoint(2) {
            max-width: 700px;
            margin-top: 80px;
            padding-bottom: 50px;
        }

        @include breakpoint(1) {
            max-width: 400px;
            margin: 0 20px;
            margin-top: 40px;
            padding-bottom: 10px;
        }

        &-title {
            font-size: 60px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: 0.42px;
            text-align: center;
            font-family: $font-pn;
            color: #ffffff;
            padding-bottom: 44px;
            @include breakpoint(1) {
                padding-bottom: 27px;
                font-size: 28px;
                line-height: 1.07;
                letter-spacing: 0.29px;
            }
        }

        &-steps {
            display: flex;
            flex-direction: row;

            .steps-number {
                margin-right: 45px;
                width: 50px;
                @include breakpoint(2) {
                    margin-right: 30px;
                }
                @include breakpoint(1) {
                    margin-right: 20px;
                }
            }

            .steps-text {
                width: calc(100% - 100px);
                font-family: $font-pn-light;
            }

            .steps-title {
                font-size: 30px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.13;
                letter-spacing: normal;
                color: #ffffff;
                padding-bottom: 14px;
                font-family: $font-pn-semibold;

                @include breakpoint(1) {
                    font-size: 18px;
                    line-height: 1.11;
                }
            }

            .steps-text {
                font-size: 24px;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.17;
                letter-spacing: normal;
                color: #ffffff;
                padding-bottom: 30px;

                @include breakpoint(1) {
                    font-size: 16px;
                    line-height: 1.25;
                }
            }
        }
    }

    &-number {
        font-size: 60px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: center;
        color: #55eed7;
        width: 35px;
        @include breakpoint(3) {
            font-size: 40px;
            line-height: 40px;
        }
        @include breakpoint(2) {
            font-size: 40px;
            line-height: 40px;
        }
        @include breakpoint(1) {
            font-size: 40px;
            line-height: 40px;
        }
    }

    &-line {
        height: 100%;
        width: 16px;
        border-right: dashed 1px #55eed7;
    }

    .flex-column {
        display: flex;
        flex-direction: column;
    }

    &-contributors {
        background-color: #2b4277;
        padding-top: 100px;
        @include breakpoint(1) {
            padding-top: 50px;
        }

        h2 {
            font-size: 60px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: 0.42px;
            text-align: center;
            color: #ffffff;
            margin-bottom: 0;
            padding-bottom: 0;
            font-family: $font-pn;
            @include breakpoint(1) {
                font-size: 28px;
            }
        }

        h4 {
            font-size: 24px;
            font-weight: 300;
            margin-bottom: 60px;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            text-align: center;
            color: #ffffff;
            font-family: $font-pn-light;
            margin-top: 15px;
            @include breakpoint(1) {
                font-size: 16px;
                margin-top: 10px;
                margin-bottom: 30px;
            }
        }

        &-section {
            -webkit-column-gap: 55px;
            -moz-column-gap: 55px;
            grid-column-gap: 55px;
            column-gap: 95px;
            column-count: 3;
            padding-right: 175px;
            padding-left: 175px;
            max-width: 1268px;
            margin: auto;
            @include breakpoint(2) {
                column-count: 2;
                padding-left: 54px;
                padding-right: 54px;
                column-gap: 80px;
            }

            @include breakpoint(1) {
                column-count: 1;
                padding-left: 30px;
                padding-right: 30px;
            }
        }
    }

    .contributors {
        page-break-inside: avoid;
        break-inside: avoid;
        padding-bottom: 40px;
        @include breakpoint(2) {
            padding-bottom: 30px;
        }

        @include breakpoint(1) {
            padding-bottom: 15px;
        }
        &-speciality {
            font-family: $font-rb;
            font-size: 22px;
            font-weight: normal;
            font-stretch: condensed;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.21px;
            color: #ffffff;
            border-bottom: solid 1px #6a78b7;
            padding-bottom: 10px;
            margin-bottom: 20px;
        }

        &-section {
            display: flex;
            flex-direction: column;
            .contributor {
                margin: 0 0 21px;
                padding: 0;
                //font-family: $font-pn-bold;
                font-size: 16px;
                line-height: 16px;
                list-style: none;
                -webkit-flex: 0 0 33%;
                -ms-flex: 0 0 33%;
                flex: 0 0 33%;
                display: flex;
                align-items: center;

                @include breakpoint(1) {
                    font-size: 14px;
                    line-height: 14px;
                    display: -webkit-flex;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-align-items: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-flex: 0 0 50%;
                    -ms-flex: 0 0 50%;
                    flex: 0 0 50%;
                    margin: 0 0 16px;
                }

                .headshot {
                    width: 55px;
                    height: 55px;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    border-radius: 50%;
                }

                .expert {
                    padding: 8px 12px;
                    font-family: $font-pn;
                    display: inline-block;
                    font-size: 16px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.13;
                    letter-spacing: normal;
                    color: #ccd2ff;

                    @include breakpoint(1) {
                        font-size: 14px;
                        line-height: 14px;
                        padding: 8px 8px 8px 12px;
                        display: inline-block;
                    }

                    &-title {
                        padding: 3px 12px;
                        border-radius: 10px;
                        margin-top: 5px;
                        background-color: #8290bc;
                        font-size: 13px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        letter-spacing: normal;
                        line-height: 1.08;
                        color: #1c2f5d;
                        display: table;
                        font-family: $font-pn;
                        @include breakpoint(1) {
                            padding: 5px 12px 4px;
                            font-size: 11px;
                            line-height: 11px;
                        }
                    }
                }
            }
        }
    }
    
    .dp-hub-page-title-heading-container {
        display: none !important;
    }
    .page-body {
     padding: 20px 0px;
     .decision-hub-title {
        font-weight: 600;
        font-size: 3.125rem;
        line-height: 3.125rem;        
        text-align: center;
        letter-spacing: 0.08em;
        text-transform: uppercase;        
        color: $white;
        font-family: $font-pn;
        @include breakpoint(1) {
            font-size: 2.125rem;
            line-height: 2.125rem;
        }        
     }
     hr {
        background: #8694FA;
        border-radius: 10px;
        width: 84px;
        height: 4px;
        @include breakpoint(1) {
            width: 55px;
            height: 3px;
        }        
     }
    }

    .page-footer {
		.footer-logo {
			.footer-logo-image {
				width: 127px;
				height: 28px;
			}
		}
	}
}
